// 
// 	I M P O R T S
// 



// 
// 	T Y P O G R A P H Y
// 
//@font-face
//	font-family 'Avenir-Roman'
//	font-path '../fonts/Avenir-Roman/Avenir-Roman'
//	font-weight 500
//	font-style normal
//
//@font-face
//	font-family 'Avenir-Medium'
//	font-path '../fonts/Avenir-Medium/Avenir-Medium'
//	font-weight 600
//	font-style normal
//
//@font-face
//	font-family 'Avenir-Heavy'
//	font-path '../fonts/Avenir-Heavy/Avenir-Heavy'
//	font-weight 800
//	font-style normal
//
//@font-face
//	font-family 'Avenir-Black'
//	font-path '../fonts/Avenir-Black/Avenir-Black'
//	font-weight 900
//	font-style normal



//
//	U T I L I T I E S
//
bkg($img)
	background-image url('../img/' + $img)

bkg-blend($img, $color, $opacity)
	position relative
	&::before
		content ''
		display block
		position absolute
		top 0
		left 0
		height 100%
		width 100%
		background-image url('../img/' + $img)
		background-repeat no-repeat
		background-size cover
		z-index -2
	&::after
		content ''
		display block
		position absolute
		top 0
		left 0
		height 100%
		width 100%
		background-color rgba($color, $opacity)
		z-index -1

transition-all($time = .3s, $transition-type = ease)
	transition all $time $transition-type
	
reset-list()
	padding-left 0
	margin-bottom 0
	list-style-type none



//
// 	M I X I N S
//




//
// 	V A R I A B L E S
//
$wco-blue = #1c4370
$wco-gray = #6d6e71
$wco-light-gray = #dcddde
$white = #ffffff




//
// 	S I T E W I D E
//
html,
body
	height 100%
	font-family 'Montserrat', sans-serif
	font-weight 500
	font-style normal
	
body
	background transparent
	&::before
		content ''
		display block
		width 100%
		height 100%
		background-image url('../img/background-texture.png')
		position fixed
		top 0
		z-index -2
	&::after
		content ''
		display block
		width 100%
		height 100%
		background linear-gradient(to right, rgba(255,255,255,0), $white 30%, $white 70%, rgba(255,255,255,0))
		background-size 100% 200%
		background-position 0 50%
		background-repeat no-repeat
		position fixed
		top 0
		z-index -1
	.btn-lg
		font-size: 18px
article
	img
		max-width 100%
	
for i in 1..6
	h{i}
		font-family 'Montserrat', sans-serif
		font-weight 600
		font-style normal

p
	a
		color $wco-blue
		font-weight bold
	
.btn
	padding .25em 1.5em
	background $wco-blue
	border-radius 5px
	color $white
	text-transform uppercase



// 
// 	N A V I G A T I O N
// 
header
	position fixed
	top 0
	left 0
	width 100%
	background $white
	border-bottom 5px solid $wco-blue
	z-index 9
	.navbar
		padding 20px 50px
	.navbar-brand
		width 250px
		img
			max-width 100%
	.nav-link
		margin-right .5em
		font-size 17px
		font-family 'Montserrat', sans-serif
		font-weight 500
		font-style normal
		color $gray
		text-transform uppercase
		transition-all()
		&:hover
			color $wco-blue
			transition-all()
		&.dropdown-toggle::after
			height 10px
			width 15px
			position relative 
			top 6px
			left 2px
			border 0
			background-image url('../img/down-arrow.png')
			background-repeat no-repeat
	.current-menu-ancestor .nav-link,
	.nav-link.active
			color $wco-blue
			font-family 'Montserrat', sans-serif
			font-weight 600
			font-style normal
			transform translate3d(0,0,0)
	.dropdown-menu
		top 60px
		background $wco-blue
		border-radius 0
		border 0
		&::before
			content ''
			display block
			position absolute
			top -15px
			left 15%
			height 0
			border-style solid
			border-width 0 15px 15px 15px
			border-color transparent transparent $wco-blue transparent
			transform rotate(360deg)
		.dropdown-item
			padding-top .5em
			padding-bottom .5em
			color $white
			text-transform uppercase
			&.active
				background-color $wco-light-gray
				color $wco-blue
				&:hover
					background-color $wco-light-gray
					color $wco-blue
			&:hover
				background rgba(0,0,0,.15)
			
	.navbar-toggler
		padding .5em
		border 2px solid $wco-blue
		justify-self flex-end
		.navbar-toggler-line
			width 25px
			height 2px
			background $wco-blue
			display block
			&:nth-child(2)
				margin 5px 0
				
	.header-links
		order 2
		&.mobile
			display none

	.phone
		@extends header .nav-link		
		color $wco-blue
		border-bottom 2px solid
		&:hover
			text-decoration none
		
	.btn
		font-family 'Montserrat', sans-serif
		font-weight 600
		font-style normal
		margin-left 1em
		&:hover
			color $white

// 
//	H O M E P A G E
// 
.home
	.hero
		position relative
		height 0
		padding-bottom percentage(715/1900)
		bkg('hero-overlay.png')
		background-repeat no-repeat
		background-size 100%
		margin-top 85px
		&__content
			position absolute
			top 0
			left 0
			width 100%
			height 100%
			display flex
			align-items center
			justify-content center
			flex-direction column
			color $white
			text-align center
		&__text
			margin-top -5%
			max-width 100%
		&__title
			margin-bottom 0
			font-size responsive 32px 57px
			font-range 500px 991px
			font-weight 900
			font-family 'Montserrat', sans-serif
			font-style normal
			line-height 60px
			text-transform uppercase
		&__subtitle
			font-size responsive 22px 33px
			font-range 500px 991px
			font-family 'Montserrat', sans-serif
			font-weight 500
			font-style normal
		.scroll-down
			margin 0 auto
			position absolute
			bottom 20%
			left 0
			right 0
			cursor pointer
			
	.intro
		margin auto
		position relative
		.dash
			margin auto
			box-sizing border-box
			padding 0
			width 0px
			height 80px
			border 2px solid $wco-blue
			border-radius 15px
			transform translateY(-2vw)
		.container
			width 1275px
			max-width 95%
		p
			margin auto
			width 1050px
			max-width 95%
			color $wco-gray
			font-size responsive 26px 33px
			font-range 991px 1700px
			font-family 'Montserrat', sans-serif
			font-weight 500
			font-style normal
			text-align center
			line-height 1.2
			span
				color $wco-blue
		
		.btn_wrap
			margin .5em auto
			font-size 33px
			font-family 'Montserrat', sans-serif
			font-weight 600
			font-style normal
			text-align center
			a
				color $wco-blue
				text-decoration underline
				

	.services
		margin 50px auto 150px
		.container
			width 1325px
			max-width 95%
		.service
			text-align center
			&__icon
				margin-bottom 2.25em
			&__title
				font-family 'Montserrat', sans-serif
				font-weight 600
				font-style normal
				font-size 18px
				color $wco-blue
			&__text
				font-family 'Montserrat', sans-serif
				font-weight 500
				font-style normal
				font-size 15px
				color $gray
				line-height 1.25
			&__btn
				background $wco-blue
				color $white
				text-transform uppercase

	.pre-footer
		position relative
		padding 35px 0 10px
		background $wco-blue
		color $white
		&::before
			content ''
			display block
			width 100%
			height 100px
			position absolute
			top 0
			left 0
			transform translateY(-100%)
			background-image url('../img/blue-bump.png')
			background-position center bottom
			background-repeat no-repeat
		.container
			width 1300px
			max-width 95%
		.left-half
			padding-right 50px
			
		.topic
			padding-bottom 1em
			&__header
				display flex
				justify-content space-between
				margin-bottom 1em
			&__title
				margin-bottom 0
				font-size 18px
				line-height 22px
			&__more
				color $white
				font-size 18px
				font-family 'Montserrat', sans-serif
				font-weight 600
				font-style normal
				text-transform lowercase
				font-variant small-caps
				line-height 22px
				
	footer
		margin-top 0
		

// 
// F O O T E R
// 
footer
	margin-top 100px
	display grid
	grid-template-columns 1fr 1fr
	padding 10px 50px
	background $white
	box-shadow 0 -2px 12px 8px rgba($wco-gray, .3)
	font-family 'Montserrat', sans-serif
	font-weight 500
	font-style normal
	color $wco-gray
	font-size 17px
	margin-bottom 0
	address
		display inline-block
		margin-bottom 0
	a
		color $wco-gray
		&:hover
			color $wco-gray
	p
		display inline-block
		margin-bottom 0
	.footer-right
		text-align right
		.metro-credit
			a
				font-weight 500
		


// 
// S E R V I C E S
// 
.secondary
	padding-top 150px
	header
		top 0
	.grid
		margin auto
		max-width 95%
		width 1275px
		display grid
		grid-template-columns 400px minmax(0, 1fr)
		grid-column-gap 50px
		
	article
		grid-row-start 1
		grid-column 2/3
		
		.featured-img
			max-width 100% 
			margin-bottom 24px

		h1, h2, h3
			line-height 60px
			margin-bottom 15px
			color $wco-blue
			
		p
			margin-bottom 30px
			font-size 17px
			line-height 30px
			color $wco-gray
			a
				font-family 'Montserrat', sans-serif
				font-weight Black
				font-style normal
				&:hover
					color $wco-blue
		ol,
		ul
			margin-bottom 30px
			li
				line-height 30px
				font-size 17px
				color $wco-gray
			a
				color $wco-blue
				font-family 'Montserrat', sans-serif
				font-weight 600
				font-style normal
			
		.blocks-gallery-item
			margin-bottom 0
			
		.split-photos
			display grid
			grid-template-columns 1fr 1fr
			grid-column-gap 10px
			margin-bottom 30px
			
		.resource-thumbs
			display grid
			grid-template-columns repeat(3, 1fr)
			grid-auto-flow row
			
			.wp-block-file
				min-width 175px
				display flex
				flex-direction column
				align-items center
				justify-content center
			img
				max-width 50px
			.filename
				display block
				padding 6px 0
			.wp-block-file__button
				background $wco-blue
				text-transform uppercase
				padding-left 1em
				padding-right 1em
				border-radius 5px
			
		.accordion
			margin-bottom 30px
			.panel-group
				overflow hidden
			.panel
				border-bottom 1px solid $white
				&:nth-last-child(1)
					border-bottom 0
			.panel-heading
				margin-top 18px
				padding 10px 25px
				display flex
				border-radius 5px
				align-items center
				background-color $wco-blue
				h4
					margin 0
					width 100%
				a
					color $white
					display flex
					justify-content space-between
					align-items center
					&:hover
						text-decoration none
					img
						transform rotate(0)
						transition-all()
						&.upArrow
							transform rotate(-180deg)
							transition-all()
			
			.panel-body
				padding 18px 24px 18px
				background $wco-light-gray
				border-bottom-left-radius 5px
				border-bottom-right-radius 5px
				p:last-of-type
					margin-bottom 0
		
	aside
		grid-row-start 1
		grid-column 1/2
		
		.sidebar-item
			background $wco-light-gray
			padding 25px
			border-radius 5px
			margin-bottom 24px
			.media-body
				a:hover
					color $wco-blue
			
		
		.emergency-service
			img
				margin-right 15px
			.title
				color $wco-blue
				font-family 'Montserrat', sans-serif
				font-weight 600
				font-style normal
				text-transform uppercase
			p
				color $wco-gray
				font-family 'Montserrat', sans-serif
				font-weight 600
				font-style normal
				margin-bottom 0
				
		.nav_menu-2
			div
				> .menu
					reset-list()
					> li 
						margin-bottom 12px
						> a
							display block
							padding 10px 25px
							border-radius 5px
							color $white
							background $wco-blue
							text-transform uppercase
							font-size 17px
						&.menu-item-has-children
							> a
								border-bottom-left-radius 0
								border-bottom-right-radius 0
								&:hover
									text-decoration none
									cursor unset
						
						ul
							reset-list()
							padding 15px 25px
							background $wco-light-gray
							border-bottom-left-radius 5px
							border-bottom-right-radius 5px
							li
								a
									display inline-block
									min-width 200px
									color $wco-gray
									line-height 30px
								&.current-menu-item
									a
										display flex
										align-items center
										font-family 'Montserrat', sans-serif
										font-weight 600
										font-style normal
										&::before
											content ''
											display inline-block
											width 25px
											height 2px
											margin-right 12px
											background $wco-gray
											
.page-contact
	.split-layout
		display grid
		grid-template-columns repeat(2, minmax(0, 50%) )
		grid-gap 50px
	
	.info
		ul
			reset-list()
			margin-bottom 12px
			strong
				color $wco-gray
			address
				margin-bottom 0
				margin-top 12px			



// 
// L E G A C Y
// 
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
	body
		header
			.navbar
				.container-fluid
					display block

			.navbar-brand
				float left
				position relative
				top 15px
				
			.header-links
				float right
				display inline-block
			.navbar-collapse
				float right
				width 75%
			.navbar-toggler
				float right
		
		main
			width 1200px
			max-width 100%
			display flex
			article
				flex 1
				order 2
				float right
				margin-bottom 150px
			aside
				order 1
				width 375px
				margin-right 50px
				margin-bottom 150px
		
		footer
			clear both
			.footer-left
				width 50%
				display inline-block
			.footer-right
				display inline-block
				width 48%

				
	.page-contact
		article
			.split-layout
				.map
					height 300px
					margin-bottom 36px
				
@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) and (max-width: 1375px) and (min-width: 991px)
	body.home 
		section.hero
			margin-top 97px
	
	header
		div.navbar-collapse
			width 100%
	
@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) and (max-width: 1000px)
	body
		footer
			.footer-left,
			.footer-right
				display block
				width 100%
				text-align center

@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) and (max-width: 991px)
	body.home
		section.hero
			margin-top 65px
			
	body 
		article
			width 100%

		
@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) and (max-width: 750px)
	header
		.navbar-collapse
			.header-links
				width 100%
				
@media only screen and (-ms-high-contrast: none), (-ms-high-contrast: active) and (max-width: 450px)
	body
		header
			.navbar-brand
				top 0




// 
// R E S P O N S I V E
// 
+below(1650px)
	header
		.phone
			margin-left 1.5em
			margin-right 1em
		.btn-lg
			margin-left 0
			
+below(1595px)
	a.nav-link,
	.header-links a
		font-size 15px
		
+below(1462px)
	header
		.navbar
			padding 12px 50px 6px
			.container-fluid
				display grid
				grid-template-columns auto 1fr
				grid-template-rows auto
				grid-row-gap 12px
		.navbar-brand
			grid-column 1/2
			grid-row 1/3
		.navbar-collapse
			grid-column 2/3
			grid-row 2/3
		.header-links
			grid-column 2/3
			grid-row 1/2
			display flex
			justify-content flex-end
			align-items flex-end
		.phone
			margin-right .75em
			
		.dropdown-menu
			top 44px
			border 0
			padding 0
	
+between(991px, 1375px)
	.home 
		.hero
			margin-top 109px

+below(1050px)
	header
		.navbar
			padding-left 2.5%
			padding-right 2.5%
			

+below(991px)
	.home
		.hero
			margin-top 69px
			height 375px
			padding-bottom 0
			background-size auto 400px
			background-position center bottom
			
		.intro
			.dash
				height 60px
			
		.services
			.service
				margin 0 auto 36px
				width 375px
				max-width 100%
				&__icon
					margin-bottom 12px
			
	.secondary
		padding-top 100px
		.grid
			display block
			width 815px
		aside
			.nav_menu-2
				display none

	footer
		grid-template-columns 1fr
		.footer-left,
		.footer-right
			text-align center

+below(1200px)
	header
		.navbar
			padding-bottom 28px
			.navbar-brand
				grid-row 1/2
			.container-fluid
				grid-template-columns auto 1fr auto
			.navbar-collapse
				grid-column 1/4
				grid-row 2/3
				.nav-link
					margin-left auto
					margin-right auto
					text-align center
					&.dropdown-toggle
						margin-left 15px
				.navbar-nav
					margin 25px auto
					width 414px
					max-width 100%
				// .dropdown-item
				// text-align center
				.dropdown-menu
					background transparent
					border-top 1px solid $wco-blue
					border-bottom 1px solid $wco-blue
					.dropdown-item
						text-align center
						color $wco-gray
						&.active
							background transparent
					&::before
						display none
				.menu-item-has-children
					&.show
						.nav-link
							font-family 'Montserrat', sans-serif
							color darken($wco-gray, .5)
					&.current-menu-ancestor
						.nav-link
							color $wco-blue

		.header-links
			margin-right 1.5em
			
+below(800px)
	.home
		.intro
			.dash
				margin 18px auto
				height 60px

+below(768px)
	.home
		.pre-footer
			padding-top 0
			padding-bottom 24px
			.left-half
				margin-bottom 24px
				
	.secondary
		article
			.wp-block-gallery
				flex-direction column
				align-items center
				li
					margin-left 0
					margin-right 0
				.blocks-gallery-item
					width unset
				img
					margin-top 12px
				
			.split-photos
				grid-template-columns 1fr
				grid-row-gap 12px
				img
					margin 12px auto 0
			

+below(750px)
	header
		.navbar
			.navbar-collapse .navbar-nav
				margin-bottom 6px
			.header-links
				display none
				&.mobile
					margin 0
					display flex
					flex-direction column
					align-items center
					.phone
						margin 1em auto
					.btn-lg
						margin-bottom 1em
			.navbar-toggler
				justify-content flex-end

+below(700px)
	.page-contact
		.split-layout
			grid-template-columns 1fr
			grid-template-rows 1fr 300px
			.map
				order 2
			.info
				order 1
				p
					margin-bottom 0


+below(675px)
	.home
		.hero
			height 350px
			background-size auto 400px
			background-position center center
	
	.secondary
		article
			.resource-thumbs
				grid-template-columns repeat(2, 1fr)
				grid-row-gap 12px
				

+below(575px)
	.home
		.intro
			p
				width 375px
				font-size 22px
				line-height 36px
			.btn_wrap
				font-size 22px


+below(550px)
	footer
		font-size 15px
		.separator
			display none
		.footer-left
			display flex
			flex-direction column
			margin-bottom 1em
		.footer-right
			display flex
			flex-direction column-reverse
			

+below(500px)
	.home
		.hero
			.hero__title
				line-height 1.2
			.hero__subtitle
				line-height 1.2
				margin-top 12px
			.scroll-down
				bottom 14%
				width 20px
			
	.intro
		.dash
			height 50px
			border-width 2Q

	footer
		margin-top 0

+below(415px)
	.home				
		.intro
			.scroll-down
				width 20px

					
+below(375px)
	.secondary
		article
			.resource-thumbs
				grid-template-columns 1fr
				grid-row-gap 18px

				
+below(350px)
	header
		.navbar
			.navbar-brand
				width 200px
	.home
		.pre-footer
			.topic__header
				flex-direction column
				.topic__more
					margin-bottom 6px
			